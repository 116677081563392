import { Button, LinearProgress, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Grid from '@mui/material/Unstable_Grid2';
import MyAppBar from 'components/AppBar/AppBar';
import ErrorView from 'components/Error/ErrorView';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'services/APIClient';
import { USER_DEVICES_URL } from 'services/APIConstants';
import Interceptor from 'services/Interceptor';
import { AuthToken, isLoggedIn } from 'services/Token';

const StoreSelector = () => {
  const [selectedPosId, setSelectedPosId] = useState(null);
  const { isFetching, data, error } = useFetch(
    USER_DEVICES_URL,
    AuthToken(),
    isLoggedIn() ? Interceptor : null
  );
  const navigate = useNavigate();

  if (isFetching) {
    return (
      <Typography variant="h6" align="top" mt={'0%'}>
        <LinearProgress />
      </Typography>
    );
  }

  if (error) {
    return <ErrorView statusCode={error.code} />;
  }

  const savePosData = (uuid) => {
    let posData = {};
    for (let i = 0; i < data.results.length; i++) {
      posData[data.results[i].uuid] = data.results[i].machine_name;
      if (uuid === data.results[i].uuid) {
        posData.tax1_rate = data.results[i].tax1_rate || 0;
        posData.tax2_rate = data.results[i].tax2_rate || 0;
        posData.tax3_rate = data.results[i].tax3_rate || 0;
      }
    }
    posData.current = uuid;
    localStorage.setItem('selectedPos', JSON.stringify(posData));
  };

  return (
    <>
      <MyAppBar isMain={true} />
      <Grid
        container
        alignItem="center"
        justifyContent="center"
        sx={{ marginTop: '100px' }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <FormControl
            sx={{
              backgroundColor: 'white',
              color: 'black',
              padding: '30px',
              borderRadius: '10px',
              maxWidth: '50%',
              maxHeight: '80vh',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FormLabel sx={{ textAlign: 'center', marginBottom: '15px' }}>
              Select Your POS Device
            </FormLabel>

            {/* Scrollable container for radio buttons */}
            <Box
              sx={{
                overflowY: 'auto',
                maxHeight: '50vh',
                marginBottom: '15px',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#f1f1f1',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: '#555',
                },
              }}
            >
              <RadioGroup
                name="pos-radio-buttons-group"
                sx={{ textAlign: 'center' }}
              >
                {data.results.map((pos, index) => (
                  <FormControlLabel
                    key={index}
                    value={pos.uuid}
                    control={<Radio />}
                    label={pos.machine_name}
                    onClick={() => {
                      setSelectedPosId(pos.uuid);
                      savePosData(pos.uuid);
                    }}
                  />
                ))}
              </RadioGroup>
            </Box>

            <Button
              variant="outlined"
              onClick={() =>
                selectedPosId ? navigate('/stores/' + selectedPosId) : null
              }
              disabled={!selectedPosId}
              sx={{ marginBottom: '8px' }}
            >
              Proceed
            </Button>
            <Typography variant="caption">
              NOTE: Selecting a POS will redirect to its related stores
            </Typography>
          </FormControl>
        </Backdrop>
      </Grid>
    </>
  );
};

export default StoreSelector;
